import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { API } from "../../../core/_base/crud";
import { SettingCategories } from "../../shared/enums/settings/settingCategories";
import { SettingCodes } from "../../shared/enums/settings/settingCodes";
import { IRequestPagination } from "../../../core/_base/crud/interfaces/request-pagination";
import { IResponsePagination } from "../../../core/_base/crud/interfaces/response-pagination";
import { ContractModel } from "./contracts.model";
import { ReverseSellVehicleRequest } from "./contract-details/reverse-sell-vehicle/reverse-sell-vehicle-request";
import { ContractOfferStatus } from "../../shared/enums/entity/contractOfferStatus";
import { IResponse } from "../../../core/_base/crud/interfaces/response-interface";
import { ICreditroCase, IGetCreditroCase } from "./contract-details/creditro-case/creditro-interfaces";
import { IVehicleTracker } from "../vehicles-management/vehicle-trackers/vehicle-tracker.model";

@Injectable()
export class ContractsService {
  constructor(private http: HttpClient) {}

  createProduct(product): Observable<any> {
    return this.http.post<any>(API.contracts, product);
  }

  getProductById(productId: number, entity_type): Observable<any> {
    if (entity_type === "contract") return this.http.get<any>(API.contracts + `/${productId}`);
    else return this.http.get<any>(API.offers + `/${productId}`);
  }

  indexProduct(params: IRequestPagination): Observable<any> {
    return this.http.get<IResponsePagination<ContractModel>>(API.contracts, {
      params: { ...params },
    });
  }

  updateProduct(product, productId): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${productId}`, product);
  }

  deleteProduct(productId: number): Observable<any> {
    return this.http.delete<any>(`${API.contracts}/${productId}`);
  }

  getLookups(category_code): Observable<any> {
    return this.http.get<any>(`${API.lookups}?category_code=${category_code}`);
  }

  getInsuranceCompanies(q: string = ""): Observable<any> {
    return this.http.get<any>(`${API.API_URL}/insurance-companies/getAll/list`, {
      params: {
        q,
        order_column: "name",
        order_dir: "asc",
      },
    });
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${API.roles}/getAll/list`);
  }

  getVehiclesList(q, vehicle_status_codes?: any[]): Observable<any> {
    if (vehicle_status_codes == null) {
      return this.http.get<any>(`${API.vehicles}/getAll/list`, {
        params: {
          q,
        },
      });
    } else {
      return this.http.get<any>(
        `${API.vehicles}/getAll/list?
				vehicle_status_codes[0]=${vehicle_status_codes[0]}&
				vehicle_status_codes[1]=${vehicle_status_codes[1]}&
				vehicle_status_codes[2]=${vehicle_status_codes[2]}`,
        {
          params: {
            q,
          },
        }
      );
    }
  }

  getVehicleMakeList(q) {
    return this.http.get<any>(`${API.vehiclesMakes}/getAll/list`, {
      params: {
        q,
        order_column: "name",
        order_dir: "asc",
      },
    });
  }

  getVehicleMakeDetails(make_id) {
    return this.http.get<any>(`${API.vehiclesMakes}/${make_id}`);
  }

  getVehicleDetails(vehicle_id) {
    return this.http.get<any>(`${API.vehicles}/${vehicle_id}`);
  }

  getVehicleByAssetId(vehicle_id) {
    return this.http.get<any>(`${API.vehicles}`, {
      params: { asset_id: vehicle_id },
    });
  }

  getCustomerDetails(customer_id) {
    return this.http.get<any>(`${API.customers}/${customer_id}`);
  }

  chooseAssetEntity(entity_id, payload, entity_type) {
    return this.http.post<any>(`${API.entities}/${entity_type}/${entity_id}/choose-asset`, payload);
  }

  chooseCustomersEntity(payload, entity_type) {
    return this.http.post<any>(`${API.entities}/${entity_type}/choose-customer`, payload);
  }

  createEntity(entity_id, entity_type, payload) {
    return this.http.post<any>(`${API.entities}/${entity_type}/${entity_id}/create`, payload);
  }

  getContractInvoices(contract_id: any, per_page: number) {
    return this.http.get<any>(`${API.contractInvoice}/getAll`, {
      params: {
        order_column: "date",
        order_dir: "asc",
        per_page,
        contract_id: contract_id,
      },
    });
  }

  getContractPayments(contract_id: any, per_page: number) {
    return this.http.get<any>(`${API.payments}/getAll/list`, {
      params: {
        order_column: "date",
        per_page,
        order_dir: "asc",
        contract_id: contract_id,
      },
    });
  }

  getContractTransactions(contract_id: any, per_page: number) {
    return this.http.get<any>(`${API.transactions}/getAll/list`, {
      params: {
        order_column: "date",
        order_dir: "asc",
        contract_id: contract_id,
        per_page,
      },
    });
  }

  getContractMileageList(contract_id: any) {
    return this.http.get<any>(`${API.contractMileage}/getAll/list`, {
      params: { contract_id },
    });
  }

  getMileageData(contract_id: any): Observable<any> {
    return this.http.get<any>(API.contracts + `/${contract_id}/get-mileage`);
  }

  deleteMileage(productId) {
    return this.http.delete<any>(`${API.contractMileage}/${productId}`);
  }

  getContractAmortization(contract_id: any, per_page: number) {
    return this.http.get<any>(`${API.contractAmortization}/getAll/list`, {
      params: {
        order_column: "date",
        order_dir: "asc",
        per_page,
        contract_id: contract_id,
      },
    });
  }

  deletePayment(productId) {
    return this.http.delete<any>(`${API.payments}/${productId}`);
  }

  createInvoice(payload) {
    return this.http.post<any>(`${API.contractInvoice}/create`, payload);
  }

  downloadContractInvoiceDetails(contract_id, invoice_id) {
    return this.http.get(`${API.contractInvoice}/${invoice_id}/download`, {
      responseType: "blob",
    });
  }

  deleteTransactionSet(productIds, id) {
    return this.http.delete<any>(`${API.transactionSets}/delete`, {
      body: { contract_id: id, transaction_set_ids: productIds },
    });
  }

  reverseTransactionSet(productIds, id) {
    return this.http.post<any>(`${API.transactionSets}/reverse`, {
      contract_id: id,
      transaction_set_ids: productIds,
    });
  }

  createFirstPaymentTask(contractId, payload) {
    return this.http.post<any>(`${API.contracts}/${contractId}/create-first-payments`, payload);
  }

  prepareFirstPayment(contract_id, product): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/prepare-first-payments`, product);
  }

  isFirstPayment(contract_id): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/is-first-payment`, {});
  }

  prepareEndContract(contract_id, product): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/prepare-end-contract`, product);
  }

  prepareSellVehicle(contract_id, product): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contract_id}/prepare-sell-vehicle`, product);
  }

  sellVehicle(contractId: any, product) {
    return this.http.post<any>(API.contracts + `/${contractId}/sell-vehicle`, product);
  }

  reverseSellVehicle(contractId: number, payload?: ReverseSellVehicleRequest) {
    return this.http.post<any>(API.contracts + `/${contractId}/reverse-sell-vehicle`, payload);
  }

  endContract(contractId: any, product) {
    return this.http.post<any>(API.contracts + `/${contractId}/end-contract`, product);
  }

  sendSignerLink(signerId, email: string) {
    return this.http.post<any>(API.contracts + `/resend-signing-link/${signerId}`, { email });
  }

  markContractAsDone(contractId, taskId) {
    return this.http.post<any>(API.contracts + `/${contractId}/mark-task/${taskId}/complete`, null);
  }

  createMonthlyPaymentTask(contractId, payload) {
    return this.http.post<any>(`${API.contracts}/${contractId}/create-monthly-payments`, payload);
  }

  downloadTransactionsFiles(transaction_set_id) {
    return this.http.get(`${API.transactions}/download-files`, {
      params: { transaction_set_id },
    });
  }

  creditApproval(contractId, status) {
    return this.http.post<any>(`${API.contracts}/${contractId}/credit-approval`, status);
  }

  createInsurance(contractId, payload) {
    return this.http.post<any>(`${API.contracts}/${contractId}/create-insurance`, payload);
  }

  createContractFromContract(contract_id) {
    return this.http.post<any>(`${API.contracts}/${contract_id}/create-another`, null);
  }

  getContractId(contract_number): Observable<any> {
    return this.http.get<any>(API.contracts + "/number" + `/${contract_number}`);
  }

  changeContractCustomer(contract_id, payload) {
    return this.http.post<any>(`${API.contracts}/${contract_id}/change-debtor`, payload);
  }

  getDigitalSignatureMode() {
    return this.http
      .get<any>(`${API.settings}`, {
        params: { category: SettingCategories.CUSTOM_SETTING },
      })
      .pipe(map(({ data }) => data.find((item) => item.code === SettingCodes.DIGITAL_SIGNATURE)));
  }

  getSettingsWith(category: string) {
    return this.http.get<any>(`${API.settings}`, {
      params: { category },
    });
  }

  downloadContractPDF(id: number) {
    return this.http.get(`${API.contracts}/${id}/download`);
  }

  getContractAttachments(contractId: number) {
    return this.http.get(`${API.contracts}/${contractId}/attachments`);
  }

  addContractAttachments(contractId: number, payload: FormData) {
    return this.http.post(`${API.contracts}/${contractId}/add-attachments`, payload);
  }

  removeContractAttachments(contractId: number, attachmentId: number) {
    return this.http.post(`${API.contracts}/${contractId}/del-attachments`, {
      id: attachmentId,
    });
  }

  getCustomSettings(category_code): Observable<any> {
    return this.http.get<any>(`${API.settings}?category=${category_code}`);
  }

  uploadContractManually(contract_id: number, fd: FormData) {
    return this.http.post<any>(`${API.contracts}/${contract_id}/change-contract-documents`, fd);
  }

  generateContractAttachment(contract_id: number, templates: string[]) {
    return this.http.post<any>(`${API.contracts}/${contract_id}/generate-draft-attachments`, null, {
      params: { "templates[]": templates },
    });
  }

  removeGeneratedAttachments(contract_id: number) {
    return this.http.post<any>(`${API.contracts}/${contract_id}/delete-draft-attachments`, null, {
      params: {
        is_generated: "1",
      },
    });
  }

  restSingingTask(contractId: number): Observable<any> {
    return this.http.get<object>(`${API.contracts}/${contractId}/reset-signing-task`);
  }

  getCreditApprovalData(contractId: number): Observable<any> {
    return this.http.get<any>(`${API.contracts}/${contractId}/credit-approval-data`);
  }

  createMonthioCaseFile(customers, contractId: number): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contractId}/create-monthio-casefile`, customers);
  }

  deleteMonthioCaseFile(contractId: number): Observable<any> {
    return this.http.delete<any>(`${API.contracts}/${contractId}/delete-monthio-casefile`);
  }

  uploadVehicleRegistrationTaxDocument(contractId: number, file): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contractId}/upload-vehicle-registration-documents`, file);
  }

  getVehicleRegistrationTaxDocuments(contractId: number): Observable<any> {
    return this.http.get<any>(`${API.contracts}/${contractId}/get-vehicle-registration-documents`);
  }

  updateContractStatus(id: number, status: ContractOfferStatus): Observable<any> {
    return this.http.put<any>(`${API.contracts}/${id}/update-status`, { status });
  }

  insertContractCustomFields(contractId: number, payload: any): Observable<any> {
    return this.http.post<any>(`${API.contracts}/${contractId}/custom-fields`, payload);
  }

  getContractCustomFields(contractId: number): Observable<any> {
    return this.http.get<any>(`${API.contracts}/${contractId}/custom-fields`);
  }

  createCreditroCase(contractId: number, payload: any): Observable<IResponse<ICreditroCase>> {
    return this.http.post<IResponse<ICreditroCase>>(`${API.contracts}/${contractId}/create-creditro-case`, payload);
  }

  getCreditroCase(contractId: number): Observable<IResponse<IGetCreditroCase>> {
    return this.http.get<IResponse<IGetCreditroCase>>(`${API.contracts}/${contractId}/get-creditro-case`);
  }

  riskAssessment(contract, product): Observable<any> {
    return this.http.put<any>(`${API.contracts}/${contract}/risk-class`, product);
  }

  orderTrackerTask(contractId: number): Observable<void> {
    return this.http.post<void>(`${API.contracts}/${contractId}/order-tracker`, {});
  }

  approveOrderTrackerTask(contractId: number): Observable<void> {
    return this.http.post<void>(`${API.contracts}/${contractId}/approve-order-tracker-task`, {});
  }

  updateTrackerTask(contractId: number): Observable<void> {
    return this.http.post<void>(`${API.contracts}/${contractId}/update-tracker`, {});
  }

  getVehicleTracker(contractId: number): Observable<IResponse<IVehicleTracker>> {
    return this.http.get<IResponse<IVehicleTracker>>(`${API.contracts}/${contractId}/get-tracker`);
  }
}
