// Angular
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// Components
import { BaseComponent } from "./views/theme/base/base.component";
import { ErrorPageComponent } from "./views/theme/content/error-page/error-page.component";
// Auth
import { NgxPermissionsGuard } from "ngx-permissions";
import { AuthGuard } from "./core/auth";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("app/views/pages/auth/auth.module").then((m) => m.AuthModule),
  },

  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: "dashboard",
      //   loadChildren: () => import("app/views/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
      // },
      {
        path: "settings",
        loadChildren: () => import("app/views/pages/settings/settings.module").then((m) => m.SettingsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: [
              "view_settings",
              "view_templates",
              "view_users",
              "view_roles",
              "view_permissions",
              "view_dealerships",
              "view_makes",
              "view_products",
              "view_suppliers",
              "view_funding_rates",
            ],
            redirectTo: "/dashboard",
          },
        },
      },
      {
        path: "contracts",
        loadChildren: () => import("app/views/pages/contracts/contracts.module").then((m) => m.ContractsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            //   only: ['view_contracts'],
            redirectTo: "/offers",
          },
        },
      },
      {
        path: "calculator",
        loadChildren: () => import("app/views/pages/calculator/calculator.module").then((m) => m.ContractsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            //   only: ['create_contract'],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "offers",
        loadChildren: () => import("app/views/pages/offers/offers.module").then((m) => m.OffersModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            //   only: ['view_users'],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "user-management/users",
        loadChildren: () => import("app/views/pages/users/users.module").then((m) => m.UsersModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_users"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "user-management/roles",
        loadChildren: () => import("app/views/pages/roles/roles.module").then((m) => m.RolesModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_roles"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "user-management/permissions",
        loadChildren: () => import("app/views/pages/permissions/permissions.module").then((m) => m.PermissionsModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_permissions"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "customers",
        loadChildren: () => import("app/views/pages/customers/customers.module").then((m) => m.CustomersModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_customers"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "dealerships",
        data: {
          permissions: {
            only: ["view_dealerships"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import("app/views/pages/dealerships/dealerships.module").then((m) => m.DealershipsModule),
      },
      {
        path: "suppliers",
        data: {
          permissions: {
            only: ["view_suppliers"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import("app/views/pages/suppliers/suppliers.module").then((m) => m.SuppliersModule),
      },
      {
        path: "templates",
        data: {
          permissions: {
            only: ["view_dealerships"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import("app/views/pages/templates/templates.module").then((m) => m.TemplatesModule),
      },
      {
        path: "funding-rates",
        data: {
          permissions: {
            only: ["view_funding_rates"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import("app/views/pages/funding-rates/funding-rates.module").then((m) => m.FundingRatesModule),
      },
      {
        path: "registration-tax",
        data: {
          permissions: {
            only: ["view_registration_settings"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import("app/views/pages/registration-tax/registration-tax.module").then((m) => m.RegistrationTaxModule),
      },
      {
        path: "registration-tax-interest-rate",
        data: {
          permissions: {
            only: ["view_registration_tax_interest_rate"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import("./views/pages/registration-tax-interest-rate/registration-tax-interest-rate.module").then(
            (m) => m.RegistrationTaxInterestRateModule
          ),
      },
      {
        path: "departments",
        data: {
          permissions: {
            only: ["view_departments"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import("app/views/pages/departments/departments.module").then((m) => m.DepartmentsModule),
      },
      {
        path: "customer-risk-classes",
        // data: {
        // 	permissions: {
        // 	  only: ['*'],
        // 	  redirectTo: '/contracts'
        // 	}
        // },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import("app/views/pages/customer-risk-classification/risk-classification.module").then(
            (m) => m.RiskClassificationModule
          ),
      },
      {
        path: "lookups/:resource",
        data: {
          permissions: {
            only: ["view_lookups"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () =>
          import("./views/pages/administration-setting/administration-setting.module").then(
            (m) => m.AdministrationSettingModule
          ),
      },
      {
        path: "products",
        data: {
          permissions: {
            only: ["view_products"],
            redirectTo: "/contracts",
          },
        },
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import("app/views/pages/products/products.module").then((m) => m.ProductsModule),
      },
      {
        path: "vehicle-makes",
        loadChildren: () =>
          import("app/views/pages/vehicles-management/vehicles-makes/vehicles-makes.module").then(
            (m) => m.VehiclesMakesModule
          ),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_makes"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "vehicles",
        loadChildren: () =>
          import("app/views/pages/vehicles-management/vehicles/vehicles.module").then((m) => m.VehiclesModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_vehicles"],
            redirectTo: "error/403",
          },
        },
      },
      {
        path: "vehicle-trackers",
        loadChildren: () =>
          import("app/views/pages/vehicles-management/vehicles-trackers/vehicles-trackers.module").then(
            (m) => m.VehiclesTrackersModule
          ),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["manage_tracker_entities"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "vehicles",
        loadChildren: () =>
          import("app/views/pages/vehicles-management/vehicles/vehicles.module").then((m) => m.VehiclesModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_vehicles"],
            redirectTo: "error/403",
          },
        },
      },
      {
        path: "vehicle-trackers",
        loadChildren: () =>
          import("app/views/pages/vehicles-management/vehicles-trackers/vehicles-trackers.module").then(
            (m) => m.VehiclesTrackersModule
          ),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            // only: ["view_vehicles"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "tasks",
        loadChildren: () => import("app/views/pages/tasks/tasks.module").then((m) => m.TasksModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["view_vehicles"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "system-tasks",
        loadChildren: () => import("app/views/pages/system-tasks/system-tasks.module").then((m) => m.SystemTasksModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ["assign_tasks_to_departments"],
            redirectTo: "/contracts",
          },
        },
      },
      {
        path: "user-profile",
        loadChildren: () => import("app/views/pages/user-profile/user-profile.module").then((m) => m.UserProfileModule),
      },
      {
        path: "wizard",
        loadChildren: () => import("app/views/pages/wizard/wizard.module").then((m) => m.WizardModule),
      },
      {
        path: "patchNote",
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import("app/views/pages/patch-note/patch-note.module").then((m) => m.PatchNoteModule),
      },
      {
        path: "faqs",
        canActivate: [NgxPermissionsGuard],
        loadChildren: () => import("app/views/pages/faq/faq.module").then((m) => m.FAQModule),
      },
      {
        path: "error/403",
        component: ErrorPageComponent,
        data: {
          type: "error-v1",
          code: 403,
          title: "403... Access forbidden",
          desc: "Looks like you don't have permission to access for requested page.<br> Please, contact administrator",
        },
      },
      { path: "error/:type", component: ErrorPageComponent },
      { path: "", redirectTo: "contracts", pathMatch: "full" },
      { path: "**", redirectTo: "contracts", pathMatch: "full" },
    ],
  },

  { path: "**", redirectTo: "error/403", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
